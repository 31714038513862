<template>
  <div class="customer-service">
    <img src="../../assets/official.png"/>
    <div class="scan-desc">微信扫码（长按保存识别）</div>
    <div class="cs-desc">
      <div class="head">客服在线时间 10:00-18:00</div>
      <div>1、支付成功后，系统在10分钟内会给您的邮箱发送邀请链接，此过程请耐心等待，如有疑问请联系客服</div>
      <div style="margin-top: 0.24rem;">2、公众号内每日提供最新的Switch游戏折扣资讯</div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data () {
    return {
      orders: [],
      value: ""
    }
  },
  components: {
  },
  methods: {
    searchOrder () {
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.customer-service {
  width: 7.5rem;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  img {
    margin-top: 0.4rem;
    width: 2.94rem;
    height: 2.94rem;
  }
  .scan-desc {
    font-size: .24rem;
    color: #666666;
  }
  .cs-desc {
    margin-top: .4rem;
    border-radius: .16rem;
    width: 6.54rem;
    height: 3rem;
    background: linear-gradient(180deg, #E3FDF5 0%, rgba(228, 253, 245, 0.18) 100%);
    padding-left: 0.24rem;
    padding-right: 0.24rem;
    padding-top: 0.2rem;
    font-size: 0.28rem;
    color: #666666;
    line-height: 0.43rem;
    .head {
      width: 6.54rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: .1rem;
    }
  }
}
</style>
